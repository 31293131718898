<template>
  <v-app id="login">
    <v-content>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <div class="d-flex justify-center">
              <v-img
                class="mb-4"
                :src="require('@/assets/'+config.view.logo.big)"
                :max-width="config.view.logo.width"
                :max-height="config.view.logo.height"
              />
            </div>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-card class="elevation-12">
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Добро пожаловать!</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-alert
                    color="error"
                    icon="mdi-alert-box"
                    :value="errorSummary"
                  >
                    {{
                      errorSummary
                    }}
                  </v-alert>
                  <v-text-field
                    v-model="login"
                    :counter="10"
                    :rules="[rules.required]"
                    prepend-icon="mdi-account"
                    label="Логин"
                    required
                  />

                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Пароль"
                    hint="Не менее 4 символов"
                    required
                    counter
                    @click:append="showPassword = !showPassword"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-layout justify-space-between>
                    <v-btn
                      type="submit"
                      :class="{
                        'blue darken-4 white--text': valid,
                        disabled: !valid
                      }"
                      @click="handleSubmit"
                    >
                      Войти
                    </v-btn>
                    <a href="mailto:support@itsph.ru">Восстановить пароль</a>
                  </v-layout>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import store from "@/state";
const API_URL_LOGIN = process.env.VUE_APP_BASE_API_URL + "/login";

export default {
  data: () => ({
    valid: true,
    errorSummary: null,
    showPassword: false,
    login: "",
    password: "",
    rules: {
      required: (value) => !!value || "Обязательно поле для заполнения",
      min: (v) => v.length >= 4 || "Минимум 4 символа",
    },
  }),
  mounted: () => {
    // auto logout
    store.mutations.SetToken(null);
    store.mutations.SetUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.errorSummary = null;
      if (this.password.length >= 4) {
        this.$http
          .post(API_URL_LOGIN, {
            login: this.login,
            password: this.password,
          })
          .then((response) => {
            let is_admin = response.data.user.is_admin;
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("jwt", response.data.token);

            store.mutations.SetToken(response.data.token);
            store.mutations.SetUser(response.data.user);

            if (localStorage.getItem("jwt") != null) {
              this.$emit("loggedIn"); // event
              if (this.$route.params.nextUrl != null) {
                this.$router.push(this.$route.params.nextUrl);
              } else {
                if (response.data.user.roles.includes("examination/schuler") && !response.data.user.roles.includes("examination/teacher")) {
                  this.$router.push({ name: "examinationSelf" }); // только для студентов без прав отправляем на страницу экзаменации
                } else {
                  this.$router.push(this.config.behavior.router.after.login);
                }
              }
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.response);
            this.errorSummary = error.response
              ? error.response.data
              : "Вход временно запрещен.";
            this.password = null;
          });
      }
    },
  },
};
</script>
